import React, { useState } from "react"

import Layout from "../layout"
import { Section, Headline, Paragraph } from "../uikit"
import ContactSalesModal from "src/developers/ContactSales"

const NotFoundPage = ({ location }) => {
  const [isContactSalesModalOpen, setIsContactSalesModalOpen] = useState(false)
  const handleModalClose = () => setIsContactSalesModalOpen(false)
  const handleModalOpen = () => setIsContactSalesModalOpen(true)

  return (
    <Layout location={location} title="404: Not found" openModal={handleModalOpen}>
      <Section>
        <Headline>NOT FOUND</Headline>
        <Paragraph>
          You just hit a route that doesn&#39;t exist... the sadness.
        </Paragraph>
      </Section>
      <ContactSalesModal
        content={{ title: "Contact Sales" }}
        isOpen={isContactSalesModalOpen}
        onClose={handleModalClose}
      />
    </Layout>
  )
}

export default NotFoundPage
