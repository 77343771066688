import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import { useLocale } from "src/common/hooks"
import { getNodeByLocale } from "src/common/getNodesByLocale"
import moment from "moment"
import styled, { ThemeProvider } from "styled-components"
import { IntlContextProvider } from "gatsby-plugin-intl"
import "moment/locale/ru"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import useContent from "../../developers/useContent"
import themes from "../../developers/theme.json"
import ContactSalesModal from "../../developers/ContactSales"
import Header from "../../developers/Header"
import Footer from "../../developers/Footer"

import SEO from "../../seo"
import AcceptCookieForm from "../../components/AcceptCookieForm"
import ModalsContainer from "../../components/ModalsContainer"
import TopBanner from "../../common/TopBanner"
import { Title, Image, Indent } from "../../uikit"
import SubscribeForm from "../../components/PostsSection/components/SubscribeForm"

import RelatedPosts from "./components/RelatedPosts"
import Introduction from "./components/Introduction"
import Content, { mdOptions } from "./components/Content"
import ShareButtons from "./components/ShareButtons"
import CTA from "./components/CTA"
import * as S from "./styled"
import NotFoundPage from "../../pages/404"
import CodeBlock from "./components/CodeBlock"

const mdComponents = {
  pre: CodeBlock,
  ...mdOptions,
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  main {
    margin-top: 64px;
  }

  @media (max-width: 1199px) {
    main {
      margin-top: 56px;
    }
  }

  @media (max-width: 599px) {
    main {
      margin-top: 48px;
    }
  }
`

const BlogPost = ({ data, location }) => {
  const content = useContent()
  const locale = useLocale()
  const thumbnailEl = useRef(null)
  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleToggleModal = () => setIsModalOpen(prevValue => !prevValue)

  useEffect(() => {
    if (!thumbnailEl.current) {
      return
    }

    const { current } = thumbnailEl
    const handler = () => {
      setIsThumbnailLoaded(true)
    }

    current.addEventListener("load", handler)

    return () => {
      current.removeEventListener("load", handler)
    }
  }, [setIsThumbnailLoaded])

  const nodes = getNodeByLocale(
    data.post.nodes.filter(
      node => node.node_locale === locale && node.title !== null
    ),
    locale
  )

  if (nodes.length === 0) {
    return <NotFoundPage />
  }

  const hasEnglishVersion = data.post.nodes.some(node => 
    (node.node_locale === "en-US" || node.node_locale === "en" || node.node_locale === "en-us") && 
    node.title !== null
  )
  
  const hasRussianVersion = data.post.nodes.some(node => 
    (node.node_locale === "ru-ru" || node.node_locale === "ru" || node.node_locale === "ru-RU") && 
    node.title !== null
  )

  const alternateLanguages = {
    hasEnglish: hasEnglishVersion,
    hasRussian: hasRussianVersion
  }

  console.log("Blog post language versions:", alternateLanguages);

  const {
    title,
    authorPhoto,
    bpAuthor,
    createdAt,
    readLength,
    heroImage,
    heroImageTitle,
    bpIntroduction,
    bpMainBodyText,
    mainBodyTextMd,
    inTextCtaButtonHeadline,
    inTextCtaButtonLead,
    inTextCtaButtonName,
    inTextCtaButtonUrl,
    modalType,
    modalTitle,
    seoTitle,
    seoDescription,
    seoKeywords,
  } = nodes

  const relatedPosts = data.relatedPosts.nodes.filter(
    node => node.node_locale === locale && node.title
  )

  moment.locale(locale)

  let post = null
  if (bpMainBodyText && bpMainBodyText.json) {
    post = <Content json={bpMainBodyText.json} />
  } else if (mainBodyTextMd && mainBodyTextMd.childMdx.body) {
    post = (
      <MDXProvider components={mdComponents}>
        <MDXRenderer>{mainBodyTextMd.childMdx.body}</MDXRenderer>
      </MDXProvider>
    )
  }

  if (!post) return null

  return (
    <ThemeProvider theme={themes.light}>
      <IntlContextProvider>
        <S.GlobalStyle />
        <Wrap id="layout">
          <AcceptCookieForm />
          <SEO
            location={location}
            title={seoTitle}
            description={seoDescription}
            keywords={seoKeywords}
            alternateLanguages={alternateLanguages}
          />
          <TopBanner />
          <Header content={content} openModal={handleToggleModal} />
          <main>
            <S.Header>
              <Title as="h1">{title}</Title>
              <Indent lg={24} md={24} sm={24} />
              <div className="post-info-container">
                {authorPhoto && (
                  <Image
                    className="lazyload"
                    data-src={authorPhoto?.file?.url}
                    alt="Author Photo"
                    circle
                    fit
                  />
                )}
                <div className="post-info">
                  <p className="author-name">{bpAuthor}</p>
                  <span className="details">
                    <time dateTime={createdAt}>
                      {moment(createdAt).format(
                        locale === "en-US" ? "MMMM DD, YYYY" : "D MMMM YYYY"
                      )}
                      .
                    </time>{" "}
                    {readLength}
                  </span>
                </div>
              </div>
            </S.Header>
            <S.ThumnailBox>
              <Indent lg={24} md={24} sm={24} />
              <figure>
                <img
                  ref={thumbnailEl}
                  className="lazyload"
                  data-src={heroImage?.fluid?.src}
                  data-srcset={heroImage?.fluid?.srcSet}
                  alt={heroImageTitle}
                />
                {heroImageTitle && (
                  <>
                    <Indent lg={16} md={16} sm={16} />
                    <figcaption>{heroImageTitle}</figcaption>
                  </>
                )}
              </figure>
            </S.ThumnailBox>
            <S.Text as="article" data-sticky-container>
              <Introduction json={bpIntroduction?.json} />
              {post}
              {/* #TODO remove hardcode!!! */}
              <ShareButtons
                isThumbnailLoaded={isThumbnailLoaded}
                url={`https://dasha.ai${location.pathname}`}
              />
              {inTextCtaButtonName && (modalType || inTextCtaButtonUrl) && (
                <CTA
                  headline={inTextCtaButtonHeadline}
                  lead={inTextCtaButtonLead}
                  btnName={inTextCtaButtonName}
                  modalType={modalType}
                  modalTitle={modalTitle}
                  url={inTextCtaButtonUrl}
                />
              )}
            </S.Text>
          </main>
          <RelatedPosts posts={relatedPosts} />
          <S.SubscribeFormContainer>
            <SubscribeForm content={content.blog} />
          </S.SubscribeFormContainer>
          <Footer content={content.footer} />
          <ContactSalesModal
            isOpen={isModalOpen}
            onClose={handleToggleModal}
            content={content.salesModal}
          />
          <ModalsContainer location={location} />
        </Wrap>
      </IntlContextProvider>
    </ThemeProvider>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String!, $relatedPosts: [String!]) {
    post: allContentfulBlogPosts(filter: { blogPostId: { eq: $slug } }) {
      nodes {
        seoTitle
        publishedAt
        seoDescription
        seoKeywords
        blogPostId
        bpAuthor
        authorPhoto {
          file {
            url
          }
        }
        bpAuthor
        bpMainBodyText {
          json
        }
        mainBodyTextMd {
          childMdx {
            body
          }
        }
        bpIntroduction {
          json
        }
        createdAt
        heroImage {
          fluid {
            src
            srcSet
          }
        }
        readLength
        heroImageTitle
        inTextCtaButtonHeadline
        inTextCtaButtonLead
        inTextCtaButtonName
        inTextCtaButtonUrl
        modalType
        modalTitle
        title
        node_locale
      }
    }

    relatedPosts: allContentfulBlogPosts(
      filter: { blogPostId: { in: $relatedPosts } }
    ) {
      nodes {
        blogPostId
        bpAuthor
        bpIntroduction {
          json
        }
        readLength
        createdAt
        heroImage {
          fixed(width: 600) {
            src
            srcSet
          }
        }
        heroImageTitle
        title
        node_locale
      }
    }
  }
`
